<template>
  <div class="d-flex" style="gap: 0.5rem">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="disabled"
          elevation="0"
          v-bind="attrs"
          v-on="on"
          @click="focusFilter"
          class="px-0"
        >
          <img
            :src="selectedDdi.image"
            class="rounded mr-1"
            height="25px"
            width="25px"
            loading="lazy"
            :alt="`Bandeira de ${selectedDdi.name}`"
          />
          +{{ selectedDdi.ddi }}
        </v-btn>
      </template>
      <v-card class="pa-1">
        <v-text-field
          @click.stop
          dense
          outlined
          hide-details
          v-model="filter"
          label="Pesquisar"
          ref="filter"
          @keypress.enter="selectFirst"
        />
        <v-list style="height: 200px; overflow-y: auto">
          <v-list-item
            v-for="(item, index) in ddiFilteredTable"
            :key="index"
            @click="select(item)"
            class="rounded px-3"
            :class="{ primary: item.ddi === selectedDdi.ddi }"
          >
            <v-list-item-content class="py-0">
              <v-list-item-title class="d-flex align-center">
                <img
                  :src="item.image"
                  class="rounded mr-1"
                  height="25px"
                  width="25px"
                  loading="lazy"
                  :alt="`Bandeira de ${item.name}`"
                />
                +{{ item.ddi }} {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-text-field-simplemask
      v-model="phoneValue"
      :label="label"
      ref="wa"
      class="flex-grow-1"
      v-bind:properties="{
        prefix: '',
        suffix: '',
        outlined: dense,
        disabled: disabled,
        dense: dense,
        type: 'tel',
        placeholder: computedMask,
        rules: [
          (v) => !!v || 'WhatsApp é obrigatório',
          (v) => selectedDdi.pattern.test(v) || 'WhatsApp inválido',
        ],
      }"
      v-bind:options="{
        inputMask: computedMask,
        outputMask: computedMask,
        empty: '',
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }"
      @paste="handleInternalPaste"
    />
  </div>
</template>

<script>
export default {
  emits: ["valid", "update:phone", "update:ddi"],
  data() {
    return {
      filter: "",
      ddiTable: [
        {
          name: "Brasil",
          ddi: 55,
          pattern: /\(\d{2}\)\s\d{4,5}-\d{4}/,
          emoji: "🇧🇷",
          image: "br",
          mask: (value) =>
            /\([0-9]{2}\) [2-4]/.test(value)
              ? "(##) ####-####"
              : "(##) #####-####",
        },
        {
          name: "Paraguai",
          ddi: 595,
          pattern: /\(\d{3}\)\s\d{3}-\d{3}/,
          emoji: "🇵🇾",
          image: "py",
          mask: "(###) ###-###",
        },
        {
          name: "Argentina",
          ddi: 54,
          pattern: /\(\d{3}\)\s\d{3}-\d{3,4}/,
          emoji: "🇦🇷",
          image: "ar",
          mask: "(###) ###-####",
        },
        {
          name: "Peru",
          ddi: 51,
          pattern: /\(\d{3}\)\s\d{3}-\d{3}/,
          emoji: "🇵🇪",
          image: "pe",
          mask: "(###) ###-###",
        },
        {
          name: "Uruguai",
          ddi: 598,
          pattern: /\(\d{3}\)\s\d{3}-\d{3}/,
          emoji: "🇺🇾",
          image: "uy",
          mask: "(###) ###-###",
        },
        {
          name: "Chile",
          ddi: 56,
          pattern: /\(\d{3}\)\s\d{3}-\d{4}/,
          emoji: "🇨🇱",
          image: "cl",
          mask: "(###) ###-####",
        },
        {
          name: "Estados Unidos",
          ddi: 1,
          pattern: /\(\d{3}\)\s\d{3}-\d{4}/,
          emoji: "🇺🇸",
          image: "us",
          mask: "(###) ###-####",
        },
        {
          name: "Irlanda",
          ddi: 353,
          pattern: /\d{2}\s\d{4}\s\d{4}/,
          emoji: "🇮🇪",
          image: "ie",
          mask: "## ### ####",
        },
        {
          name: "Honduras",
          ddi: 504,
          pattern: /\d{4}-\d{4}/,
          emoji: "🇭🇳",
          image: "hn",
          mask: "####-####",
        },
        {
          name: "Reino Unido",
          ddi: 44,
          pattern: /\d{4}\s\d{6}/,
          emoji: "🇬🇧",
          image: "gb",
          mask: "#### ######",
        },
        {
          name: "Alemanha",
          ddi: 49,
          pattern: /\d{3}-\d{3}\d{3,8}/,
          emoji: "🇩🇪",
          image: "de",
          mask: "###-###-####",
        },
        {
          name: "França",
          ddi: 33,
          pattern: /\d{1}\s\d{2}\s\d{2}\s\d{2}\s\d{2}/,
          emoji: "🇫🇷",
          image: "fr",
          mask: "# ## ## ## ##",
        },
        {
          name: "Austrália",
          ddi: 61,
          pattern: /\d{1,2}\s\d{4}\s\d{4}/,
          emoji: "🇦🇺",
          image: "au",
          mask: "## #### ####",
        },
        {
          name: "Japão",
          ddi: 81,
          pattern: /\d{1,4}-\d{1,4}-\d{1,4}/,
          emoji: "🇯🇵",
          image: "jp",
          mask: "##-####-####",
        },
        {
          name: "Índia",
          ddi: 91,
          pattern: /\d{4}\s\d{3}\s\d{3}/,
          emoji: "🇮🇳",
          image: "in",
          mask: "#### ### ###",
        },
        {
          name: "África do Sul",
          ddi: 27,
          pattern: /\d{2}\s\d{3}\s\d{4}/,
          emoji: "🇿🇦",
          image: "za",
          mask: "## ### ####",
        },
      ],
    };
  },
  methods: {
    handleInternalPaste(event) {
      const pastedData = event.clipboardData.getData("text").trim();

      if (pastedData.startsWith("+")) {
        const [ddi, ...phone] = pastedData.split(" ");
        this.ddiValue = parseInt(ddi.replace(/\D/g, ""));
        if (ddi === "+55" && phone[1].length === 9) {
          this.phoneValue = phone.map((p) => p.replace(/\D/g, "")).join("9");
        } else {
          this.phoneValue = phone.join(" ").replace(/\D/g, "");
        }
      }
    },
    isValid(phone) {
      const valid = this.selectedDdi.pattern.test(phone || this.phoneValue);
      this.$emit("valid", valid);
    },
    selectFirst() {
      this.select(this.ddiFilteredTable[0]);
    },
    select(item) {
      if (!item) return;
      this.ddiValue = item.ddi;
      this.phoneValue = "";
      this.filter = "";
      this.$refs.wa.focus();
    },
    focusFilter() {
      // this.$nextTick(() => {
      //   this.$refs.filter.$el.focus();
      // });
    },
  },
  watch: {
    ddiValue() {
      this.isValid();
    },
    phoneValue() {
      this.isValid();
    },
  },
  computed: {
    computedMask() {
      if (typeof this.selectedDdi.mask === "function")
        return this.selectedDdi.mask(this.phoneValue);

      return this.selectedDdi.mask;
    },
    ddiValue: {
      get() {
        return this.ddi;
      },
      set(value) {
        this.$emit("update:ddi", value);
      },
    },
    phoneValue: {
      get() {
        return this.phone;
      },
      set(value) {
        this.$emit("update:phone", value);
      },
    },
    selectedDdi() {
      return this.ddiImageTable.find((item) => item.ddi === this.ddiValue);
    },
    ddiImageTable() {
      return this.ddiTable.map((item) => {
        return {
          ...item,
          image: require(`@/assets/images/flags/1x1/${item.image}.svg`),
        };
      });
    },
    ddiFilteredTable() {
      return this.ddiImageTable.filter((item) => {
        return (
          !this.filter ||
          item.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 ||
          item.ddi.toString().indexOf(this.filter) > -1
        );
      });
    },
  },
  mounted() {
    this.isValid();
    this.$nextTick(() => {
      const input = this.$refs.wa.$el.querySelector("input");
      if (input) {
        input.addEventListener("paste", this.handleInternalPaste);
      }
    });
  },
  beforeDestroy() {
    const input = this.$refs.wa.$el.querySelector("input");
    if (input) {
      input.removeEventListener("paste", this.handleInternalPaste);
    }
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
    ddi: {
      type: Number,
      default: 55,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
